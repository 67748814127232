import { Pipe, PipeTransform, inject } from '@angular/core';

import { DayjsService } from '../services/dayjs.service';
import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'dateFromNow',
  standalone: true,
})
export class DateFromNowPipe implements PipeTransform {
  private dayjsService = inject(DayjsService);
  private localeService = inject(LocaleService);

  transform(value: string): string {
    const date = this.dayjsService.dayjs(value ?? null);

    if (date.isValid()) {
      const now = this.dayjsService.dayjs();

      const second = date.diff(now, 'second');
      if (second < 0) {
        return '-';
      }
      if (second < 60) {
        return this.localeService.translate('shared.date.second', { second });
      }

      const minute = date.diff(now, 'minute');
      if (minute < 60) {
        return this.localeService.translate('shared.date.minute', { minute });
      }

      const hour = date.diff(now, 'hour');
      if (hour < 24) {
        return this.localeService.translate('shared.date.hour', { hour });
      }

      return this.localeService.translate('shared.date.day', { day: date.diff(now, 'day') });
    } else {
      return '-';
    }
  }
}
