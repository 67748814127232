import { Pipe, PipeTransform, inject } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { SLASHKIT_LOCALIZE_OPTION, SlashkitLocalizeOption } from '../models/locale';

@Pipe({
  name: 'phoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  private localizeOption = inject<SlashkitLocalizeOption>(SLASHKIT_LOCALIZE_OPTION);

  transform(value: string): string {
    const countryCodes = this.localizeOption.phoneCountryCodes;
    if (countryCodes.length === 1) {
      const parse = parsePhoneNumberFromString(value ?? '', this.localizeOption.phoneCountryCodes[0]);
      return parse?.formatNational() ?? '';
    } else {
      const numbers = countryCodes.map((code) => {
        const parse = parsePhoneNumberFromString(value ?? '', code);
        return parse?.country === code ? parse : null;
      });
      const validNumbers = numbers.filter((number) => number?.isValid());
      if (validNumbers.length === 1) {
        return validNumbers[0]?.formatInternational() ?? '';
      }

      return value ?? '';
    }
  }
}
