import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';

import { LayoutDirective } from '../../../directives/layout.directive';
import { LotteryCheckOut } from '../../../models/lottery';
import { DateFromNowPipe } from '../../../pipes/date-from-now.pipe';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { LocaleService } from '../../../services/locale.service';
import { LotteryCheckoutService } from '../../../services/lottery-checkout.service';

@Component({
  selector: 'sl-lottery-checkout, a[sl-lottery-checkout]',
  templateUrl: './lottery-checkout.component.html',
  styleUrls: ['./lottery-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutDirective, DateFromNowPipe, ImageSizePipe, LocalePipe],
})
export class LotteryCheckoutComponent implements OnInit {
  @Input() checkout: LotteryCheckOut;

  get plan() {
    return this.checkout?.paymentType === 'coupon'
      ? this.checkout?.paymentable?.name
      : this.localeService.translate('components.lotteryCheckout.quantity', {
          quantity: this.checkout?.quantity,
        });
  }

  private readonly lotteryCheckoutService = inject(LotteryCheckoutService);
  private readonly localeService = inject(LocaleService);

  ngOnInit() {}

  getCheckoutShipmentStatus(checkout: LotteryCheckOut) {
    return this.lotteryCheckoutService.getCheckoutShipmentStatus(checkout);
  }
}
