@if (getCheckoutShipmentStatus(checkout); as status) {
  <div class="item {{ status }}" slLayout>
    <div class="item-thumbnail">
      <div class="thumbnail">
        <img [src]="checkout?.machine?.thumbnailImage | imageSize: 'medium'" alt="" class="thumbnail-image" />
      </div>
    </div>
    <div class="item-info">
      <div class="machine">{{ checkout?.machine?.name }}</div>
      <div class="plan">{{ checkout?.purchaseMethodName }}</div>
      @if (checkout.status === 'refunded') {
        <div class="refunded">{{ 'components.lotteryCheckout.statusRefunded' | locale }}</div>
      } @else {
        @if (!checkout?.notExchangeable) {
          <div class="status">
            @switch (status) {
              @case ('noShipping') {
                {{ 'shared.shipmentStatus.noShipping' | locale: { dueAt: checkout.exchangeDueAt | dateFromNow } }}
              }
              @case ('accepted') {
                {{ 'shared.shipmentStatus.accepted' | locale }}
              }
              @case ('shipped') {
                {{ 'shared.shipmentStatus.shipped' | locale }}
              }
              @case ('expired') {
                {{ 'shared.shipmentStatus.expired' | locale }}
              }
            }
          </div>
        }
      }
    </div>
  </div>
}
