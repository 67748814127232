<div class="login">
  @if (showSignUpButton) {
    <sl-button class="register-button" type="filled" (click)="signUp()">{{
      'shared.account.registerAccount' | locale
    }}</sl-button>
  }

  <div class="login-header">
    <div class="login-header-content" #ContentChild><ng-content></ng-content></div>
    @if (!ContentChild.hasChildNodes()) {
      {{ 'shared.account.hasAccountMessage' | locale }}
    }
  </div>
  <sl-button class="login-button" type="stroked" (click)="signIn()">{{ 'shared.account.signIn' | locale }}</sl-button>
</div>
