import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

import { LotteryCheckoutApi } from '../apis/lottery-checkout';
import { PAGE_SIZE } from '../config';
import { PaginatedList } from '../libs/paginated-list';
import { LotteryCheckOut } from '../models/lottery';
import { ShipmentStatus } from '../models/shipment';
import {
  selectLotteryCheckOut,
  selectLotteryCheckOuts,
  setLotteryCheckOut,
  setLotteryCheckOuts,
} from '../store/lottery-checkout';
import { AppState } from '../store/symbols';

import { DayjsService } from './dayjs.service';

@Injectable({ providedIn: 'root' })
export class LotteryCheckoutService {
  checkoutPage: PaginatedList<LotteryCheckOut> = new PaginatedList<LotteryCheckOut>(PAGE_SIZE);
  checkouts$: Observable<LotteryCheckOut[]>;

  private readonly api = inject(LotteryCheckoutApi);
  private readonly store = inject<Store<AppState>>(Store);
  private readonly dayjsService = inject(DayjsService);

  constructor() {
    this.checkouts$ = this.store.select(selectLotteryCheckOuts);
  }

  selectCheckout(checkoutId: string): Observable<LotteryCheckOut> {
    return this.store.select(selectLotteryCheckOut(checkoutId));
  }

  fetchCheckouts(init: boolean = false): Observable<LotteryCheckOut[]> {
    const page = init ? 1 : this.checkoutPage.page + 1;
    return this.api.fetchCheckouts(page, PAGE_SIZE).pipe(
      tap((checkouts) => {
        this.store.dispatch(setLotteryCheckOuts(checkouts, init));
        this.checkoutPage.page = page;
        this.checkoutPage.setPage(checkouts);
      }),
    );
  }

  fetchCheckout(checkoutId: string): Observable<LotteryCheckOut> {
    return this.api.fetchCheckout(checkoutId).pipe(
      tap((checkout) => {
        this.store.dispatch(setLotteryCheckOut({ checkout }));
      }),
    );
  }

  fetchExpiredCheckouts(init: boolean = false): Observable<LotteryCheckOut[]> {
    const page = init ? 1 : this.checkoutPage.page + 1;
    return this.api.fetchExpiredCheckouts(page, PAGE_SIZE).pipe(
      tap((checkouts) => {
        this.store.dispatch(setLotteryCheckOuts(checkouts, init));
        this.checkoutPage.page = page;
        this.checkoutPage.setPage(checkouts);
      }),
    );
  }

  getCheckoutShipmentStatus(checkout: LotteryCheckOut): ShipmentStatus | 'expired' | 'noShipping' {
    if (checkout?.shipment) {
      return checkout.shipment?.status;
    }

    const exchangeDueAt = this.dayjsService.dayjs(checkout.exchangeDueAt);
    if (this.dayjsService.dayjs().isAfter(exchangeDueAt)) {
      return 'expired';
    }
    return 'noShipping';
  }
}
