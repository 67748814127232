import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Observable, distinctUntilChanged } from 'rxjs';
import { LayoutDirective } from '../../../directives/layout.directive';

@Component({
  selector: 'sl-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutDirective],
})
export class NavbarComponent implements OnInit, ControlValueAccessor {
  control = new FormControl();
  controlDir = inject(NgControl, { optional: true, self: true });

  constructor() {
    if (this.controlDir) {
      this.controlDir.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  get onChanged(): Observable<any> {
    return this.control.valueChanges.pipe(distinctUntilChanged());
  }

  updateValue(value: unknown): void {
    this.control.setValue(value);
    this.onChangeCallback(value);
  }

  writeValue(value: unknown): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }
  onTouchedCallback: () => void = () => {};
  onChangeCallback: (_: any) => void = () => {};
}
