import { Pipe, PipeTransform, inject } from '@angular/core';

import { LotteryPaymentable } from '../models/lottery';
import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'paymentMethodName',
  standalone: true,
})
export class PaymentMethodNamePipe implements PipeTransform {
  private localeService = inject(LocaleService);

  transform(value: LotteryPaymentable): unknown {
    if (!value) {
      return '';
    }

    if (value.type === 'link_payment') {
      return this.localeService.translate(`shared.paymentMethodName.${value.paymentType}`);
    }
    if (value.type === 'card_payment') {
      return this.localeService.translate(`shared.paymentMethodName.creditCard`);
    }

    // クーポンは現状だと表示に使われていない
    // mypage-history-detail.page ではクーポンの名前を表示している
    if (value.type === 'gacha_coupon') {
      return this.localeService.translate(`shared.paymentMethodName.coupon`);
    }

    return '';
  }
}
