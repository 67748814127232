import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { LotteryCheckOut } from '../models/lottery';

@Injectable({ providedIn: 'root' })
export class LotteryCheckoutApi {
  private readonly http = inject(HttpClient);

  fetchCheckouts(page: number, perPage: number): Observable<LotteryCheckOut[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<LotteryCheckOut[]>(`v1/private/gachas/check_outs`, { params });
  }

  fetchCheckout(checkoutId: string): Observable<LotteryCheckOut> {
    return this.http.get<LotteryCheckOut>(`v1/private/gachas/check_outs/${checkoutId}`);
  }

  fetchExpiredCheckouts(page: number, perPage: number): Observable<LotteryCheckOut[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<LotteryCheckOut[]>(`v1/private/gachas/check_outs/expired`, { params });
  }
}
