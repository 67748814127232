import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntil } from 'rxjs';

import { LayoutDirective } from '../../../directives/layout.directive';
import { IconComponent } from '../../icon/icon.component';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'sl-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutDirective, IconComponent],
})
export class NavbarItemComponent implements OnInit, OnDestroy {
  parent = inject(NavbarComponent, { optional: true });

  @Input() value: any;
  isActive = false;
  @Input() notice = false;

  private readonly cd = inject(ChangeDetectorRef);
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    this.parent?.onChanged.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.isActive = this.value === value;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit();
  }

  onClick(): void {
    this.parent.updateValue(this.value);
  }
}
