import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';

import { LocalePipe } from '../../pipes/locale.pipe';
import { SessionService } from '../../services/auth/session.service';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'sl-login-buttons',
  templateUrl: './login-buttons.component.html',
  styleUrls: ['./login-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, LocalePipe],
})
export class LoginButtonsComponent implements OnInit {
  @Input() showSignUpButton = true;

  private readonly sessionService = inject(SessionService);

  ngOnInit(): void {}

  signUp() {
    this.sessionService.signUp();
  }

  signIn() {
    this.sessionService.signIn();
  }
}
